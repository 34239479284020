import PropTypes from 'prop-types';

const shape = PropTypes.shape({
  id: PropTypes.number,
  icon: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
});

const preset = {
  id: 0,
  icon: '',
  title: '',
  content: '',
};

export default {
  shape,
  preset,
};
