import React from 'react';
import styled from 'styled-components';
import MainLayout from '../layout/MainLayout';
import SEO from '../components/Universal/SEO';
import '../assets/css/global.css';
import InfographicBanner from '../components/Infographic/Banner/InfographicBanner';
import InfographicIntroduction from '../components/Infographic/Introduction/InfographicIntroduction';
import MainContainer from '../components/Universal/Container/MainContainer';
import InfographicDataA from '../components/Infographic/Data/InfographicDataA';
import InfographicDataB from '../components/Infographic/Data/InfographicDataB';
import InfographicDataC from '../components/Infographic/Data/InfographicDataC';
import InfographicDataD from '../components/Infographic/Data/InfographicDataD';
import InfographicDataE from '../components/Infographic/Data/InfographicDataE';
import Contact from '../components/Contact/Contact';
import { BREAK_POINT_MOBILE } from '../assets/layout';

const Padding = styled.div`
  height: 100px;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    height: 200px;
  }
`;

const DESC = '永齡基金會與社企流攜手，打造明日戶政所，提供少子女化議題現況、彙整政府資源與國內外創新行動。我們相信生兒育女是每個人自由的選擇與權利，邀請你一起從關注議題開始，為促進友善兼容的生育環境踏出第一步！';

function Index() {
  return (
    <MainLayout>
      <SEO title="議題資訊 | 明日戶政所——少子女化全解析" description={DESC} />
      <InfographicBanner />
      <InfographicIntroduction />
      <MainContainer>
        <InfographicDataA />
        <InfographicDataB />
        <InfographicDataC />
        <InfographicDataD />
        <InfographicDataE />
        <Padding />
      </MainContainer>
      <Contact />
    </MainLayout>
  );
}

export default Index;
