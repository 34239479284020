/* eslint-disable comma-dangle */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import useGoogleSheet from '../../../hooks/useGoogleSheet';
import env from '../../../env';
import { lineChartConfig, setChartColor, chartOptions } from './chartConfig';
import DataTitle from './DataTitle';
import DataSource from './DataSource';
import BGIMG from '../../../assets/icons/bar01_1.png';
import BGIMGM from '../../../assets/icons/bar01_1_mobile.png';
import InfographicLoading from './InfographicLoading';

const SourceData = [
  {
    text: '世界銀行',
    link: 'https://data.worldbank.org/indicator/SP.DYN.TFRT.IN',
  },
  {
    text: '內政部戶政司',
    link: 'https://www.ris.gov.tw/app/portal/346',
  },
  {
    text: '維基百科',
    link:
      'https://zh.wikipedia.org/wiki/%E5%90%84%E5%9B%BD%E7%94%9F%E8%82%B2%E7%8E%87%E5%88%97%E8%A1%A8',
  },
];

const Wrapper = styled.div`
  margin-bottom: 3rem;
`;

const Title = styled(DataTitle)`
  text-align: justify;
`;

function InfographicA() {
  const [data, loading] = useGoogleSheet(
    'dataMode',
    env.FH_INFOGRAPHIC_SHEET_ID,
    '圖一：生育率',
  );

  const renderData = useMemo(() => {
    if (!data) return null;

    const labels = [];
    const aData = [];
    const bData = [];
    data.forEach((item) => {
      labels.push(item.year);
      aData.push(Number(item.taiwan_fertility_rate.replace(/,/g, '')));
      bData.push(Number(item.world_fertility_rate.replace(/,/g, '')));
    });

    let aDataConfig = { ...lineChartConfig };
    aDataConfig = setChartColor(aDataConfig, '75,192,192');
    aDataConfig.label = '臺灣總生育率（人）';
    aDataConfig.data = aData;

    let bDataConfig = { ...lineChartConfig };
    bDataConfig = setChartColor(bDataConfig, '240,131,97');
    bDataConfig.label = '世界平均總生育率（人）';
    bDataConfig.data = bData;

    const lineData = {
      labels,
      datasets: [aDataConfig, bDataConfig],
    };

    return (
      <>
        <Line data={lineData} options={chartOptions('年份', '總生育率')} />
      </>
    );
  }, [data]);

  return (
    <Wrapper>
      <Title
        data={{
          background: BGIMG,
          backgroundMobile: BGIMGM,
          title: '你知道嗎？臺灣總生育率是全球排名倒數第三',
          depiction: [
            '臺灣近年來總生育率直直落，根據最新統計，目前臺灣每位婦女平均一生只生育一個小孩，低於世界平均。',
          ],
        }}
      />
      {loading ? <InfographicLoading /> : renderData}
      <DataSource data={SourceData} />
    </Wrapper>
  );
}

export default InfographicA;
