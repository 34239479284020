import React from 'react';
import styled from 'styled-components';
import DataTitle from './DataTitle';
import InfographicDataDMore from './InfographicDataDMore';
import DataSource from './DataSource';
import BGIMG from '../../../assets/icons/bar01_4.png';
import BGIMGM from '../../../assets/icons/bar01_4_mobile.png';
import INFOPNG from '../../../assets/images/Infographi4.png';
import INFOPNGM from '../../../assets/images/Infographi4_mobile.png';
import { useViewport } from '../../../hooks/useViewport';

const SourceData = [
  {
    text: '內政部戶政司',
    link: 'https://www.ris.gov.tw/app/portal/346',
  },
  {
    text: '六都市政府各局處網頁（主計處、社會局、教育局、民政局）',
    link: null,
  },
];

const Wrapper = styled.div`
  padding: 1rem;
  margin-bottom: 2rem;
`;

const Image = styled.img`
  width: 100%;
`;

const MoreText = styled.div`
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

function InfographicD() {
  const [viewport] = useViewport();
  const getBG = () => {
    if (viewport === 'mobile') {
      return INFOPNGM;
    }
    return INFOPNG;
  };

  return (
    <Wrapper>
      <DataTitle
        data={{
          background: BGIMG,
          backgroundMobile: BGIMGM,
          title: '聚焦六都看一看！近年來生、托育補助與人口現況一覽',
          depiction: [
            '・平均總生育率前三名為：桃園市、臺北市、臺中市',
            '・近五年，處於生育年齡的婦女佔總人口比例前三名為：桃園市、臺中市、新北市',
            '・近五年，生、托育補助預算前三名為：臺北市、桃園市、新北市',
            '・近五年，處於生育年齡的婦女，平均每位可分被配到的生、托育補助預算前三名為：臺北市、桃園市、臺中市',
          ],
        }}
      />
      <Image src={getBG()} alt="" />
      <DataSource data={SourceData} />
      <MoreText>點擊看更多六都資訊</MoreText>
      <InfographicDataDMore />
    </Wrapper>
  );
}

export default InfographicD;
