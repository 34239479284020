/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { BREAK_POINT_TABLET } from '../../../assets/layout';
import { H3 } from '../../Universal/Title/H';
import { numberWithCommas } from '../../../utils/helper';
import CloseImg from '../../../assets/icons/fh_close_green.png';
import { useViewport } from '../../../hooks/useViewport';

const Title = styled(H3)`
  margin-bottom: 8px;
`;

const SubTitle = styled.div`
  font-weight: bold;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  margin-top: ${(props) => `-${props.size * 0.35}px`};
  min-height: ${(props) => `${props.size * 0.5}px`};
  max-width: 95vw;
  min-width: 60vw;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 15px 22px 0 #d8d8d8;
  border: solid 2px #50d9e3;
  background-color: rgba(228, 249, 251, 0.95);
  transform: ${(props) =>
    props.isShow ? 'translateX(-50%)' : 'translateX(-200%)'};
  transition: all 300ms;
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    transform: ${(props) =>
    props.isShow ? 'translateX(-115%)' : 'translateX(-200%)'};
    min-width: 0;
    max-width: 40vw;
    top: 50%;
    left: 50%;
  }
`;

const ColWrapper = styled.div`
  margin-top: 1.6rem;
`;

const Col = styled.div`
  color: #9099aa;
`;

const CloseBtn = styled.button`
  border: 0;
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  &:focus {
    outline: none;
  }
  background-image: ${(props) => `url(${props.background})` || ''};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`;

function BubbleInfo({ size, data, onClose }) {
  const [viewport] = useViewport();

  return (
    <Wrapper size={size} isShow={!!data}>
      {viewport !== 'desktop' ? <CloseBtn background={CloseImg} onClick={onClose} /> : null}
      <Title>{`${get(data, 'counties') || ''}人口現況`}</Title>
      <SubTitle>{`總人口數（人）：${numberWithCommas(get(data, 'total_population') || 0)}`}</SubTitle>
      <ColWrapper>
        <Col>{`2020 年出生數（人）：${numberWithCommas(get(data, 'number_of_births') || 0)}`}</Col>
        <Col>{`2020 年自然增加數（人）：${numberWithCommas(get(data, 'population_growth') || 0)}`}</Col>
        <Col>{`2020 年總生育率（人）：${get(data, 'total_fertility_rate') || ''}`}</Col>
      </ColWrapper>
    </Wrapper>
  );
}

BubbleInfo.propTypes = {
  size: PropTypes.number,
  data: PropTypes.object,
  onClose: PropTypes.func,
};

BubbleInfo.defaultProps = {
  size: 0,
  data: {},
  onClose: () => {},
};

export default BubbleInfo;
