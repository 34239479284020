import React from 'react';
import styled from 'styled-components';
import DataTitle from './DataTitle';
import DataSource from './DataSource';
import BGIMG from '../../../assets/icons/bar01_5.png';
import BGIMGM from '../../../assets/icons/bar01_5_mobile.png';
import INFOPNG from '../../../assets/images/Infographic5.png';
import INFOPNGM from '../../../assets/images/Infographic5_mobile.png';
import { useViewport } from '../../../hooks/useViewport';

const SourceData = [
  {
    text: '國家發展委員會',
    link: 'https://www.ndc.gov.tw/Content_List.aspx?n=84223C65B6F94D72',
  },
  {
    text: '內政部戶政司',
    link: 'https://www.ris.gov.tw/app/portal/346',
  },
];

const Wrapper = styled.div``;

const Image = styled.img`
  width: 100%;
`;
const ImgWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;

function InfographicE() {
  const [viewport] = useViewport();
  const TITLE = '出生數持續減少，跟你我有什麼關係？';

  const getBG = () => {
    if (viewport === 'mobile') {
      return INFOPNGM;
    }
    return INFOPNG;
  };

  return (
    <Wrapper>
      <DataTitle
        data={{
          background: BGIMG,
          backgroundMobile: BGIMGM,
          title: TITLE,
          depiction: [
            '幼年人口下降，各教育階段學生數呈遞減趨勢，衝擊教育體系；青壯年人口減少，市場勞動力不足，影響經濟發展；老年人口增加，青壯年扶養負擔加重。',
          ],
        }}
      />
      <ImgWrapper>
        <Image src={getBG()} alt={TITLE} />
      </ImgWrapper>
      <DataSource data={SourceData} />
    </Wrapper>
  );
}

export default InfographicE;
