/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import styled from 'styled-components';
import { H3, H4 } from '../../Universal/Title/H';
import MainContainer from '../../Universal/Container/MainContainer';
import IntroductionItem from './IntroductionItem';
import { useViewport } from '../../../hooks/useViewport';

const Wrapper = styled(MainContainer)`
  text-align: center;
  padding-left: ${(props) => (props.viewport === 'mobile' ? '2.5rem' : '0px')};
  padding-right: ${(props) => (props.viewport === 'mobile' ? '2.5rem' : '0px')};
  padding-top: 7rem;
  margin-bottom: 4rem;
`;

const PWrapper = styled.p`
  text-align: ${(props) => (props.viewport === 'mobile' ? 'left' : 'center')};
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => (props.viewport === 'mobile' ? 'column' : 'row')};
  align-items: center;
`;

const DATA = [
  {
    id: 1,
    icon: 'InfographicIntroduction01',
    title: '1. 議題資訊',
    content: '梳理數據圖表與關鍵資料，讓你 5 分鐘讀懂少子女化現況',
  },
  {
    id: 2,
    icon: 'InfographicIntroduction02',
    title: '2. 政府資源',
    content: '彙整中央及地方政府的各式補助與資源，助你篩選最符合需求的資訊',
  },
  {
    id: 3,
    icon: 'InfographicIntroduction03',
    title: '3. 創新行動',
    content: '為你搜羅來自各界的創新解方，了解多元的作法與行動',
  },
  {
    id: 4,
    icon: 'InfographicIntroduction04',
    title: '4. 參與投票',
    content: '讓你表達最關注的少子女化議題，促進更多改變的可能',
  },
];

function InfographicIntroduction() {
  const [viewport] = useViewport();

  const renderItems = () =>
    DATA.map((item) => (
      <IntroductionItem key={`IntroductionItem-${item.id}`} data={item} />
    ));

  return (
    <Wrapper viewport={viewport}>
      {viewport === 'mobile' ? (
        <H4>4 個步驟，邀你一同關注少子女化議題</H4>
      ) : (
        <H3>4 個步驟，邀你一同關注少子女化議題</H3>
      )}
      <PWrapper viewport={viewport}>永齡基金會與社企流攜手，邀你 4 步驟看少子女化全解析：</PWrapper>
      <ItemContainer viewport={viewport}>{renderItems()}</ItemContainer>
    </Wrapper>
  );
}

export default InfographicIntroduction;
