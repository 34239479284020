/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import styled from 'styled-components';
import DataFold from './DataFold';
import InfographicTaipei from '../../../assets/images/Infographic4_1_Taipei.png';
import InfographicTaipeiM from '../../../assets/images/Infographic4_1_Taipei_mobile.png';
import InfographicNewTaipei from '../../../assets/images/Infographic4_2_New_Taipei.png';
import InfographicNewTaipeiM from '../../../assets/images/Infographic4_2_New_Taipei_mobile.png';
import InfographicTaoyuan from '../../../assets/images/Infographic4_3-Taoyuan.png';
import InfographicTaoyuanM from '../../../assets/images/Infographic4_3-Taoyuan_mobile.png';
import InfographicTaichung from '../../../assets/images/Infographic4_4_Taichung.png';
import InfographicTaichungM from '../../../assets/images/Infographic4_4_Taichung_mobile.png';
import InfographicTainan from '../../../assets/images/Infographic4_5_Tainan.png';
import InfographicTainanM from '../../../assets/images/Infographic4_5_Tainan_mobile.png';
import InfographicKaohsiung from '../../../assets/images/Infographic4_6_Kaohsiung.png';
import InfographicKaohsiungM from '../../../assets/images/Infographic4_6_Kaohsiung_mobile.png';
import { useViewport } from '../../../hooks/useViewport';

const CITY_DATA = [
  {
    id: 'Taipei',
    title: '臺北市',
    image: InfographicTaipei,
    imageM: InfographicTaipeiM,
  },
  {
    id: 'New_Taipei',
    title: '新北市',
    image: InfographicNewTaipei,
    imageM: InfographicNewTaipeiM,
  },
  {
    id: 'Taoyuan',
    title: '桃園市',
    image: InfographicTaoyuan,
    imageM: InfographicTaoyuanM,
  },
  {
    id: 'Taichung',
    title: '臺中市',
    image: InfographicTaichung,
    imageM: InfographicTaichungM,
  },
  {
    id: 'Tainan',
    title: '臺南市',
    image: InfographicTainan,
    imageM: InfographicTainanM,
  },
  {
    id: 'Kaohsiung',
    title: '高雄市',
    image: InfographicKaohsiung,
    imageM: InfographicKaohsiungM,
  },
];

const Wrapper = styled.div``;

function InfographicDataDMore() {
  const [viewport] = useViewport();

  const renderData = () =>
    CITY_DATA.map((city) => (
      <DataFold
        key={city.id}
        title={city.title}
        image={viewport === 'mobile' ? city.imageM : city.image}
        imageAlt={`${city.title}現況一覽`}
      />
    ));

  return <Wrapper>{renderData()}</Wrapper>;
}

export default InfographicDataDMore;
