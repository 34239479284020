/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { H3 } from '../../Universal/Title/H';
import infographicDataTitleType from '../../../propTypes/infographicDataTitleType';
import { useViewport } from '../../../hooks/useViewport';

const Title = styled(H3)`
  width: 100%;
  text-align: center;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.background})` || ''};
`;

const TitleMobile = styled(H3)`
  width: 100%;
  height: ${(props) => (props.viewport === 'mobile' ? '9.25rem' : '10rem')};
  text-align: center;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: ${(props) => `url(${props.background})` || ''};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${(props) => (props.viewport !== 'desktop' ? '2.5rem' : '0')};
  padding-right: ${(props) => (props.viewport !== 'desktop' ? '2.5rem' : '0')};
`;

const Depiction = styled.div`
  width: 100%;
  max-width: 45rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: justify;
`;

const DepictionItem = styled.div``;

function DataTitle({ className, data }) {
  const { background, backgroundMobile, title, depiction } = data;
  const [viewport] = useViewport();

  const renderDepiction = () => {
    if (!depiction) return null;
    return depiction.map((item) => (
      <DepictionItem key={nanoid()}>{item}</DepictionItem>
    ));
  };

  if (backgroundMobile === '') {
    return (
      <TitleWrapper className={className}>
        <Title background={background}>{title}</Title>
        <Depiction className={className}>{renderDepiction()}</Depiction>
      </TitleWrapper>
    );
  }

  return (
    <TitleWrapper className={className} viewport={viewport}>
      {viewport !== 'desktop' ? (
        <TitleMobile viewport={viewport} background={backgroundMobile}>
          {title}
        </TitleMobile>
      ) : (
        <Title background={background}>{title}</Title>
      )}
      <Depiction className={className}>{renderDepiction()}</Depiction>
    </TitleWrapper>
  );
}

DataTitle.propTypes = {
  className: PropTypes.string,
  data: infographicDataTitleType.shape,
};

DataTitle.defaultProps = {
  className: '',
  data: infographicDataTitleType.preset,
};

export default DataTitle;
