/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable curly */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable comma-dangle */
/* eslint-disable no-restricted-properties */
import React, { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { useViewport } from '../../../hooks/useViewport';
import { createNodes, charge } from '../../../utils/d3BubbleHelper';
import { getRandomInt } from '../../../utils/helper';
import BubbleInfo from './BubbleInfo';
import { BREAK_POINT_TABLET } from '../../../assets/layout';

const forceStrength = 0.03;

const Wrapper = styled.div`
  height: ${(props) => `${props.size}px`};
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlockSVG = styled.svg`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size * 1.5}px`};
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    width: ${(props) => `${props.size * 1.5}px`};
    height: ${(props) => `${props.size}px`};
    position: absolute;
    top: 0;
    left: 20%;
  }
`;

function D3BubbleGraphic({ data }) {
  const [nodes, setNodes] = useState(null);
  const d3Container = useRef(null);
  const [viewport] = useViewport();
  const [diameter, setDiameter] = useState(600);
  const [centre, setCentre] = useState({
    x: diameter * 1.5 * 0.65,
    y: diameter / 2,
  });
  const [lastSelectItem, setLastSelectItem] = useState(null);
  const [selectItem, setSelectItem] = useState(null);

  useEffect(() => {
    if (!nodes) {
      setNodes(createNodes(data, 'total_population'));
      // convert raw data into nodes data
    }

    switch (viewport) {
      case 'tablet':
        setDiameter(650);
        setCentre({ y: diameter * 0.5, x: diameter * 0.5 });
        break;
      case 'desktop':
        setDiameter(600);
        setCentre({ x: diameter, y: diameter * 0.5 });
        break;
      default:
        setDiameter(340);
        setCentre({ y: 340 * 0.85, x: 340 * 0.8 });
        break;
    }
  }, [data, nodes, selectItem, viewport, diameter]);

  useEffect(() => {
    if (!selectItem && nodes) {
      if (viewport === 'desktop') {
        setSelectItem(nodes[getRandomInt(nodes.length)]);
      }
    }
  }, [nodes, selectItem, viewport]);

  useEffect(() => {
    let bubbles = null;
    let labels = null;

    const s = d3.forceSimulation();
    s.force('charge', d3.forceManyBody().strength(charge))
      .force('x', d3.forceX().strength(forceStrength).x(centre.x))
      .force('y', d3.forceY().strength(forceStrength).y(centre.y))
      .force(
        'collision',
        d3.forceCollide().radius((d) => {
          if (d.counties === get(selectItem, ['counties'])) {
            const res = d.radius + (viewport === 'mobile' ? 10 : 30);
            return res;
          }
          if (d.counties === get(lastSelectItem, ['counties'])) {
            const res = d.radius - (viewport === 'mobile' ? 10 : 30);
            return res;
          }
          return d.radius + 5;
        })
      );
    s.stop();

    function ticked() {
      bubbles
        .attr('cx', (d) => (viewport === 'mobile' ? d.x * 0.6 : d.x))
        .attr('cy', (d) => (viewport === 'mobile' ? d.y * 0.6 : d.y));
      labels
        .attr('x', (d) => (viewport === 'mobile' ? d.x * 0.6 : d.x))
        .attr('y', (d) => (viewport === 'mobile' ? d.y * 0.6 : d.y));
    }

    const handleClick = (c, item) => {
      setLastSelectItem(selectItem);
      setSelectItem(item);
    };

    if (nodes) {
      d3.selectAll('.bubble').remove();
      d3.selectAll('g').remove();
      // create svg element inside provided selector
      const svg = d3.select(d3Container.current);
      // bind nodes data to circle elements
      const elements = svg.selectAll('.bubble').data(nodes).enter().append('g');
      bubbles = elements
        .append('circle')
        .classed('bubble', true)
        .attr('r', (d) => (viewport === 'mobile' ? d.radius * 0.6 : d.radius))
        .attr('fill', (d) => d.color.fill)
        .style('stroke', (d) => {
          if (d.counties === get(selectItem, ['counties'])) return '#000000';
          return d.color.stroke;
        })
        .style('stroke-width', '4px')
        .style('cursor', 'pointer')
        .on('click', handleClick);
      // labels
      labels = elements
        .append('text')
        .attr('dy', '.3em')
        .style('text-anchor', 'middle')
        .style('font-size', '0.6rem')
        .style('cursor', 'pointer')
        .on('click', handleClick)
        .text((d) => d.counties);

      s.nodes(nodes).on('tick', ticked).restart();
    }
  }, [nodes, selectItem, lastSelectItem, centre.x, centre.y, viewport]);

  const handleCloseInfo = () => {
    setLastSelectItem(selectItem);
    setSelectItem(null);
  };

  return (
    <Wrapper size={diameter}>
      <BubbleInfo size={diameter} data={selectItem} onClose={handleCloseInfo} />
      <BlockSVG
        isSelectItem={!!selectItem}
        className="d3-component"
        size={diameter}
        ref={d3Container}
      />
    </Wrapper>
  );
}

D3BubbleGraphic.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

D3BubbleGraphic.defaultProps = {
  data: [],
};

export default D3BubbleGraphic;
