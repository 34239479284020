/* eslint-disable max-len */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import styled from 'styled-components';
import background from '../../../assets/images/kv.png';
import backgroundMobile from '../../../assets/images/kv_mobile.png';
import { H1, H3 } from '../../Universal/Title/H';
import MainContainer from '../../Universal/Container/MainContainer';
import { useViewport } from '../../../hooks/useViewport';
import ScrollImage from '../../../assets/icons/scroll.png';
import ScrollImageM from '../../../assets/icons/scroll-mobile.png';

const Wrapper = styled.div`
  height: ${(props) =>
    props.viewport === 'mobile' ? 'auto' : 'calc(100vh-80px)'};
  width: 100%;
  background-color: #ffffff;
  background-image: ${(props) =>
    props.viewport === 'mobile'
      ? `url(${backgroundMobile})`
      : `url(${background})`};
  background-position: ${(props) => (props.bgPostion)};
  background-size: ${(props) => (props.bgSize)};
  background-repeat: no-repeat;
  border: white solid 1px;
  padding-bottom: ${(props) => (props.viewport === 'mobile' ? '60px' : '60px')};
`;

const Content = styled.div`
  margin-left: 2rem;
  margin-top: ${(props) => (props.marginTop)};
`;

const Depiction = styled.p`
  max-width: ${(props) => (props.viewport === 'mobile' ? '260px' : '25rem')};
  margin-top: ${(props) => (props.viewport === 'mobile' ? '1.5rem' : '2.4rem')};
  text-align: justify;
  text-justify: inter-ideograph;
`;

const Scroll = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
`;

const ScrollImg = styled.img`
  width: ${(props) => (props.viewport === 'mobile' ? '185px' : '48px')};
  height: ${(props) => (props.viewport === 'mobile' ? '112px' : '161px')};
`;

function InfographicBanner() {
  const [viewport] = useViewport();

  const getScrollImg = () => {
    if (viewport === 'mobile') {
      return ScrollImageM;
    }
    return ScrollImage;
  };

  const getBgPostion = () => {
    switch (viewport) {
      case 'mobile':
        return '0px 24px';
      case 'tablet':
        return 'right 26px';
      default:
        return 'right 80px';
    }
  };

  const getMarginTop = () => {
    switch (viewport) {
      case 'mobile':
        return '320px';
      case 'tablet':
        return '530px';
      default:
        return '260px';
    }
  };

  const getBgSize = () => {
    switch (viewport) {
      case 'mobile':
        return '100%';
      case 'tablet':
        return 'auto 50%;';
      default:
        return 'auto 70%;';
    }
  };

  return (
    <Wrapper viewport={viewport} bgPostion={getBgPostion()} bgSize={getBgSize()}>
      <MainContainer>
        <Content viewport={viewport} marginTop={getMarginTop()}>
          <H1>明日戶政所</H1>
          <H3>少子女化全解析</H3>
          <Depiction viewport={viewport}>
            歡迎光臨明日戶政所，在這裡，我們相信生兒育女是每個人自由的選擇與權利，而作為共榮永續的社會，應包容並支持每一個不同的需求，讓我們從關注議題開始，一起為促進友善兼容的生育環境踏出第一步！
          </Depiction>
        </Content>
      </MainContainer>
      <Scroll viewport={viewport}>
        <ScrollImg viewport={viewport} src={getScrollImg()} alt="" />
      </Scroll>
    </Wrapper>
  );
}

export default InfographicBanner;
