import { numberWithCommas } from '../../../utils/helper';

/* eslint-disable comma-dangle */
export const lineChartConfig = {
  label: '',
  fill: false,
  responsive: true,
  lineTension: 0.1,
  backgroundColor: '',
  borderColor: '',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: '',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 10,
  pointHoverBackgroundColor: '',
  pointHoverBorderColor: '',
  pointHoverBorderWidth: 2,
  pointRadius: 5,
  pointHitRadius: 5,
  data: null,
};

export const setChartColor = (configInput, color) => {
  const c = configInput;
  const color1 = `rgba(${color},0.4)`;
  const color2 = `rgba(${color},1)`;
  c.backgroundColor = color1;
  c.borderColor = color1;
  c.pointBorderColor = color2;
  c.pointHoverBackgroundColor = color2;
  c.pointHoverBorderColor = color2;
  return c;
};

export const chartOptions = (xLabel, yLabel, xAxesCommas = false, yAxesCommas = false) => ({
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        label += numberWithCommas(tooltipItem.yLabel);
        return label;
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value) => {
            if (xAxesCommas) return numberWithCommas(value);
            return value;
          },
        },
        scaleLabel: {
          display: true,
          labelString: xLabel,
          fontColor: '#404a5b',
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value) => {
            if (yAxesCommas) return numberWithCommas(value);
            return value;
          },
        },
        scaleLabel: {
          display: true,
          labelString: yLabel,
          fontColor: '#404a5b',
        },
        gridLines: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
  layout: {
    padding: {
      left: 20,
      right: 20,
      top: 0,
      bottom: 20,
    },
  },
});
