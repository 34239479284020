export function randomRGB() {
  const o = Math.round;
  const r = Math.random;
  const s = 255;
  return [`${o(r() * s)}`, `${o(r() * s)}`, `${o(r() * s)}`];
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function numberWithCommas(input) {
  let nStr = input;
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;

  // Safair not supported :(
  // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export default {
  randomRGB,
  getRandomInt,
};
