import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { nanoid } from 'nanoid';

const Wrapper = styled.div`
  display: flex;
  max-width: 37rem;
  justify-content: center;
  margin: 0.5rem auto;
  font-size: 12px;
  flex-wrap: wrap;
  line-height: 2.08;
`;

const ItemWrapper = styled.div`
  flex-shrink: 0;
`;

const Source = styled.a`
  color: #0000ee;
  text-decoration: underline;
  &:hover {
    color: #0000ee;
    text-decoration: underline;
  }
`;

function DataSource({ className, data }) {
  const renderLinks = () => {
    if (!data) return null;
    return data.map((item, index) => (
      <ItemWrapper key={nanoid()}>
        {index === 0 ? null : <span>、</span>}
        <Source
          href={item.link}
          rel="noreferrer"
          target="_blank"
        >
          {item.text}
        </Source>
      </ItemWrapper>
    ));
  };

  return (
    <Wrapper className={className}>
      <ItemWrapper>資料來源：</ItemWrapper>
      {renderLinks()}
    </Wrapper>
  );
}

DataSource.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

DataSource.defaultProps = {
  className: '',
  data: {},
};

export default DataSource;
