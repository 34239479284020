/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H3 } from '../../Universal/Title/H';
import Arrow from '../../../assets/svg/chevron-down-solid.svg';
import { BREAK_POINT_MOBILE } from '../../../assets/layout';

const Title = styled(H3)``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const IconDown = styled(Arrow)`
  height: 1rem;
  width: 2rem;
  transition: all 0.2s;
  transform: ${(props) => (props.open === 1 ? 'rotate(0deg)' : 'rotate(180deg)')}; ;
`;

const Bar = styled.div`
  width: 100%;
  max-width: 44rem;
  display: flex;
  justify-content: space-between;
`;

const BarBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  color: #9099aa;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const ImageWrapper = styled.div`
  max-height: ${(props) => (props.open === 1 ? 'auto' : '0px')};
  overflow: hidden;
  transition: all 0.1s;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    max-height: ${(props) => (props.open === 1 ? '418px' : '0px')};
    transition: all 0.2s;
  }
`;

const Image = styled.img`
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
`;

const Line = styled.div`
  height: 0.5rem;
  width: 100%;
  max-width: 44rem;
  border-bottom: solid 1px #9099aa;
`;

function DataFold(props) {
  const [open, setOpen] = useState(-1);
  const { className, title, image, imageAlt } = props;

  const handleClick = () => {
    setOpen(open * -1);
  };

  return (
    <Wrapper className={className}>
      <Bar>
        <Title>{title}</Title>
        <BarBtn onClick={handleClick}>
          <IconDown open={open} />
        </BarBtn>
      </Bar>
      <ImageWrapper open={open}>
        <Image src={image} alt={imageAlt} />
      </ImageWrapper>
      <Line />
    </Wrapper>
  );
}

DataFold.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
};

DataFold.defaultProps = {
  className: '',
  title: '',
  image: '',
  imageAlt: '',
};

export default DataFold;
