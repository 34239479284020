import React from 'react';
import styled from 'styled-components';
import Loading from '../../Universal/Loading/Loading';

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
`;

function InfographicLoading() {
  return (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
}

export default InfographicLoading;
