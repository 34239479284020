/* eslint-disable comma-dangle */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import useGoogleSheet from '../../../hooks/useGoogleSheet';
import env from '../../../env';
import DataTitle from './DataTitle';
import DataSource from './DataSource';
import BGIMG from '../../../assets/icons/bar01_3.png';
import BGIMGM from '../../../assets/icons/bar01_3_mobile.png';
import { renderColorSet } from '../../../utils/d3BubbleHelper';
import InfographicLoading from './InfographicLoading';
import D3BubbleGraphic from './D3BubbleGraphic';

const SourceData = [
  {
    text: '內政部戶政司',
    link: 'https://www.ris.gov.tw/app/portal/346',
  },
];

const Wrapper = styled.div`
  padding: 1rem;
`;

function InfographicC() {
  const [data, loading] = useGoogleSheet(
    'dataMode',
    env.FH_INFOGRAPHIC_SHEET_ID,
    '【更新】圖三：縣市人口現況(FC)'
  );

  const renderData = useMemo(() => {
    if (!data) return null;

    const fData = data.map((item) => {
      const t = item;
      t.number_of_births = t.number_of_births.replace(/,/g, '');
      t.population_growth = t.population_growth.replace(/,/g, '');
      t.total_population = t.total_population.replace(/,/g, '');
      t.color = renderColorSet();
      return t;
    });

    return (
      <>
        <D3BubbleGraphic data={fData} />
      </>
    );
  }, [data]);

  return (
    <Wrapper>
      <DataTitle
        data={{
          background: BGIMG,
          backgroundMobile: BGIMGM,
          title: '各縣市人口現況比一比！猜猜總生育率最高的前三名？',
          depiction: [
            '・全臺 22 縣市中，2020 年總生育率最高的前三名為彰化縣、澎湖縣、連江縣；最低的則是嘉義市、苗栗縣、臺南市',
            '・桃園市、臺中市、臺北市為 2020 年人口自然增加數最多的前三名縣市；而人口自然減少數最多的前三名是臺南市、屏東縣、雲林縣',
          ],
        }}
      />
      {loading ? <InfographicLoading /> : renderData}
      <DataSource data={SourceData} />
    </Wrapper>
  );
}

export default InfographicC;
