/* eslint-disable no-restricted-properties */
/* eslint-disable import/prefer-default-export */
import * as d3 from 'd3';
import { getRandomInt } from './helper';
// charge is dependent on size of the bubble, so bigger towards the middle
export const charge = (d) => Math.pow(d.radius, 2.0) * 0.01;

const colorSet = [
  {
    fill: '#d1f6f8',
    stroke: '#50d9e3',
  },
  {
    fill: '#e2ccfc',
    stroke: '#a760fc',
  },
  {
    fill: '#fde1f7',
    stroke: '#fd5edb',
  },
  {
    fill: '#fd5edb',
    stroke: '#d303a7',
  },
  {
    fill: '#a760fc',
    stroke: '#7620de',
  },
  {
    fill: '#50d9e3',
    stroke: '#0daab5',
  },
];

export const renderColorSet = () => colorSet[getRandomInt(6)];

// set up colour scale
export const fillColour = d3
  .scaleOrdinal()
  .domain(['1', '2', '3', '4', '5', '99'])
  .range(['#d1f6f8', '#e2ccfc', '#fde1f7', '#fd5edb', '#a760fc', '50d9e3']);

export function createNodes(rawData, keyName) {
  const maxSize = d3.max(rawData, (d) => +d[keyName]);
  const radiusScale = d3.scaleSqrt().domain([0, maxSize]).range([0, 80]);
  const myNodes = rawData.map((d) => ({
    ...d,
    radius: radiusScale(+d[keyName]),
    size: +d[keyName],
    x: Math.random() * 900,
    y: Math.random() * 800,
  }));

  return myNodes;
}
