import React from 'react';
import styled from 'styled-components';
import { H4 } from '../../Universal/Title/H';
import infographicIntroductionItemType from '../../../propTypes/infographicIntroductionItemType';
import InfographicIntroduction01 from '../../../assets/icons/Infographic_introduction_01.png';
import InfographicIntroduction02 from '../../../assets/icons/Infographic_introduction_02.png';
import InfographicIntroduction03 from '../../../assets/icons/Infographic_introduction_03.png';
import InfographicIntroduction04 from '../../../assets/icons/Infographic_introduction_04.png';
import { BREAK_POINT_MOBILE } from '../../../assets/layout';

const Wrapper = styled.div`
  text-align: center;
  padding: 1rem;
  padding-bottom: 0;
  max-width: 100%;
  justify-content: space-around;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    max-width: 11rem;
  }
`;

const IconImg = styled.img`
  width: 56px;
  height: auto;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    width: 120px;
  }
`;

const PWrapper = styled.p`
  text-align: justify;

  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    min-height: 6.5rem;
  }  
`;

const Title = styled(H4)``;

function IntroductionItem({ data }) {
  const { icon, title, content } = data;

  const renderIcon = () => {
    switch (icon) {
      case 'InfographicIntroduction01':
        return <IconImg src={InfographicIntroduction01} alt={title} />;
      case 'InfographicIntroduction02':
        return <IconImg src={InfographicIntroduction02} alt={title} />;
      case 'InfographicIntroduction03':
        return <IconImg src={InfographicIntroduction03} alt={title} />;
      case 'InfographicIntroduction04':
        return <IconImg src={InfographicIntroduction04} alt={title} />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      {renderIcon()}
      <Title>{title}</Title>
      <PWrapper>{content}</PWrapper>
    </Wrapper>
  );
}

IntroductionItem.propTypes = {
  data: infographicIntroductionItemType.shape,
};

IntroductionItem.defaultProps = {
  data: infographicIntroductionItemType.preset,
};

export default IntroductionItem;
