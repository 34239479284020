/* eslint-disable comma-dangle */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import useGoogleSheet from '../../../hooks/useGoogleSheet';
import env from '../../../env';
import { lineChartConfig, setChartColor, chartOptions } from './chartConfig';
import DataTitle from './DataTitle';
import DataSource from './DataSource';
import BGIMG from '../../../assets/icons/bar01_2.png';
import BGIMGM from '../../../assets/icons/bar01_2_mobile.png';
import InfographicLoading from './InfographicLoading';

const SourceData = [
  {
    text: '內政部戶政司',
    link: 'https://www.ris.gov.tw/app/portal/346',
  },
];

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

function InfographicB() {
  const [data, loading] = useGoogleSheet(
    'dataMode',
    env.FH_INFOGRAPHIC_SHEET_ID,
    '圖二：人口成長數'
  );

  const renderData = useMemo(() => {
    if (!data) return null;

    const labels = [];
    const aData = [];
    const bData = [];

    data.forEach((item) => {
      labels.push(item.year);
      aData.push(Number(item.number_of_births.replace(/,/g, '')));
      bData.push(Number(item.number_of_death.replace(/,/g, '')));
    });

    let aDataConfig = { ...lineChartConfig };
    aDataConfig = setChartColor(aDataConfig, '75,192,192');
    aDataConfig.label = '出生人數（人）';
    aDataConfig.data = aData;

    let bDataConfig = { ...lineChartConfig };
    bDataConfig = setChartColor(bDataConfig, '240,131,97');
    bDataConfig.label = '死亡人數（人）';
    bDataConfig.data = bData;

    const lineData = {
      labels,
      datasets: [aDataConfig, bDataConfig],
    };

    return (
      <>
        <Line data={lineData} options={chartOptions('年份', '人數', false, true)} />
      </>
    );
  }, [data]);

  return (
    <Wrapper>
      <DataTitle
        data={{
          background: BGIMG,
          backgroundMobile: BGIMGM,
          title: '臺灣人口於 2020 年首度呈現負成長',
          depiction: [
            '2017 年全國出生嬰兒數降至 20 萬人以下，並於 2020 年首度出現死亡交叉——代表出生數低於死亡數，總人口數第一次出現衰退。',
          ],
        }}
      />
      {loading ? <InfographicLoading /> : renderData}
      <DataSource data={SourceData} />
    </Wrapper>
  );
}

export default InfographicB;
